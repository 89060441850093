import React, { useEffect, useState } from "react";
import { API_PATH, LANGUAGE } from "../tools/constant";
import { getLanguage, getText } from "../locales";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import AnchorLink from 'react-anchor-link-smooth-scroll'


// import required modules
import { Navigation, Pagination } from "swiper";
import axios from "axios";
import Order from "./Order";
import ScrollToTop from "./ScrollToTop";
import { Link, useLocation, useNavigate} from "react-router-dom";
// components 
import Navbar from "../components/Navbar";

const Home = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [tour, setTour] = useState([]);
  const [burger, setBurger] = useState(false);
  const [tour_id, setTour_id] = useState("");
  const [orderModal, setOrderModal] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const [parolModal, setParolModal] = useState(false);
  const [order_id, setOrder_id] = useState("");
  const navigate = useNavigate();

  const setFalse = () => {
    setOrderModal(false);
    setParolModal(false);
    setPayModal(false);
  };

  const getTour = () => {
    axios.get(API_PATH + "news/tour-list/").then((res) => {
      setTour(res.data);
    });
  };

  useEffect(() => {
    getTour();
  }, []);

  const linkModal = (id) => {
    window.scrollTo(0, 0);
    setOrderModal(true);
    console.log(id);
    setTour_id(id);
  };

  const changeLanguage = (e) => {
    if (getLanguage() !== e) {
      localStorage.setItem(LANGUAGE, e);
      console.log(e);
      document.location.reload(true);
    }
  };

  const link = () => {
    setOrderModal(false);
    setPayModal(true);
    axios
      .post(API_PATH + "news/order-create/", {
        phone,
        email,
        surname,
        name,
        message,
        tour: tour_id,
      })
      .then((response) => {
        setOrder_id(response.data.id);
        console.log(response.data);
      });
  };

  const formData = new FormData();

  const [card, setCard] = useState("");
  const [num, setNum] = useState("");
  const [code, setCode] = useState("");
  formData.append("number", card);
  formData.append("expire", num);
  const [token, setToken] = useState("");

  const CreateCard = () => {
    setPayModal(false);
    setParolModal(true);
    axios
      .post(API_PATH + "news/card-create", formData)
      .then((res) => {
        setToken(res.data.token);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const notify = () => toast("Wow so easy!");
  const notify2 = () => toast("Xatolik mavjud");

  const formData2 = new FormData();

  formData2.append("verify_code", code);
  formData2.append("token", token);

  const send_code = () => {
    axios.post(API_PATH + "news/verify-phone/", formData2)
      .then((res) => {
        axios.post(API_PATH + "news/payment/", { order_id, offer_id: tour_id })
          .then((res) => {
            if (res.status === 200) {
              notify()
            }
          })
          .catch((err) => {
            notify()
          })
      })
      .catch((err) => {
        console.log(err)
        notify2()
      })
  };

  return (
    <>
      {/* <div className="Navbar">
        <div className="container">
          <div className="row">
            <div className="col-3 d-flex align-items-center">
              <div className="nav_lang_box">
                <div
                  onClick={() => changeLanguage("uz")}
                  value="uz"
                  className="nav_lang"
                >
                  <img src="/img/lang_2.png" alt="" />
                </div>
                <div
                  onClick={() => changeLanguage("ru")}
                  value="ru"
                  className="nav_lang"
                >
                  <img src="/img/lang_1.png" alt="" />
                </div>
                <div
                  onClick={() => changeLanguage("en")}
                  value="en"
                  className="nav_lang"
                >
                  <img src="/img/lang_3.png" alt="" />
                </div>
              </div>
            </div>
            <div
              className={`col-9 d-flex align-items-center justify-content-end nav_a_box ${burger ? "active" : ""
                }`}
            >
              <AnchorLink href="#1" className="nav_a">
                {getText("home")}
              </AnchorLink>
              <AnchorLink href="#2" className="nav_a">
                {getText("about")}
              </AnchorLink>
              <AnchorLink href="#3" className="nav_a">
                {getText("abroad")}
              </AnchorLink>
              <AnchorLink href="#4" className="nav_a">
                {getText("about_uzb")}
              </AnchorLink>
              <AnchorLink href="#5" className="nav_a">
                {getText("gallery")}
              </AnchorLink>
              <AnchorLink href="#6" className="nav_a">
                {getText("partners")}
              </AnchorLink>
              <AnchorLink href="#7" className="nav_a">
                {getText("contacts")}
              </AnchorLink>
            </div>

            <div
              onClick={() => setBurger(!burger)}
              className={`burger pr-4 ml-auto d-flex d-lg-none ${burger ? "burgered" : ""
                }`}
            >
              <div className="burger_up "></div>
              <div className="burger_down"></div>
            </div>
          </div>
        </div>
      </div> */} 
      <Navbar/>
      <div id="1" className="Header ">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 d-flex align-items-center justify-content-between flex-column h-100">
              <img src="/img/logo.svg" alt="" className="head_logo" />
              <div className="head_name">{getText("header-title")}</div>

              <div className="head_box">
                <AnchorLink href="#5" className="head_btn">
                  {getText("show-more")}
                </AnchorLink>

                <a  href="#/" className="head_arrow">
                  <img src="/img/arrow.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="2" className="About ">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12">
              <div className="about_name"> {getText("about")}</div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-3 mb-lg-0 mb-5">
              <div className="ab_box">
                <div className="ab_top">
                  <div className="ab_top_img">
                    <img src="/img/head_1.svg" alt="" />
                  </div>
                </div>
                <div className="ab_mid">
                  <div className="ab_h"> {getText("who")}</div>
                </div>
                <div className="ab_down">
                  <div className="ab_p">{getText("operator")}</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-lg-0 mb-5">
              <div className="ab_box">
                <div className="ab_top">
                  <div className="ab_top_img">
                    <img src="/img/head_2.svg" alt="" />
                  </div>
                </div>
                <div className="ab_mid">
                  <div className="ab_h">{getText("service")}</div>
                </div>
                <div className="ab_down">
                  <div className="ab_p">{getText("service-text")}</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-lg-0 mb-5">
              <div className="ab_box">
                <div className="ab_top">
                  <div className="ab_top_img">
                    <img src="/img/head_3.svg" alt="" />
                  </div>
                </div>
                <div className="ab_mid">
                  <div className="ab_h">{getText("grow")}</div>
                </div>
                <div className="ab_down">
                  <div className="ab_p">{getText("grow_text")}</div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
      <div id="3" className="Abroad ">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center">
              <img src="/img/abroad.svg" alt="" className="abr_img" />
              <div className="abr_h"> {getText("world_tour_title")}</div>
              <div className="abr_p"> {getText("abroad_tours_text")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="Branch ">
        <div className="row">
          <div className="col-lg-7 ">
            <img src="/img/branch_1.jpg" alt="" className="branch_img" />
          </div>
          <div className="col-lg-5 d-flex flex-column h-100">
            <div className="branch_box">
              <img
                src="/img/branch_logo_1.svg"
                alt=""
                className="branch_logo"
              />
              <div className="branch_h">{getText("france")}</div>
              <div className="branch_p">{getText("france_descript")}</div>
            </div>
            <img src="/img/branch_2.jpg" alt="" className="branch_img_2" />
          </div>
          <div className="col-lg-5 d-flex flex-column h-100">
            <div className="branch_box">
              <img
                src="/img/branch_logo_2.svg"
                alt=""
                className="branch_logo"
              />
              <div className="branch_h">{getText("greece")}</div>
              <div className="branch_p">{getText("greece_descript")}</div>
            </div>
            <img src="/img/branch_4.jpg" alt="" className="branch_img_2" />
          </div>
          <div className="col-lg-7 ">
            <img src="/img/branch_3.jpg" alt="" className="branch_img" />
          </div>
          <div className="col-lg-7 ">
            <img src="/img/branch_5.jpg" alt="" className="branch_img" />
          </div>
          <div className="col-lg-5 d-flex flex-column h-100">
            <div className="branch_box">
              <img
                src="/img/branch_logo_3.svg"
                alt=""
                className="branch_logo"
              />
              <div className="branch_h">{getText("uae")}</div>
              <div className="branch_p">{getText("uae_descript")}</div>
            </div>
            <img src="/img/branch_6.jpg" alt="" className="branch_img_2" />
          </div>
          <div className="col-lg-5 d-flex flex-column h-100">
            <div className="branch_box">
              <img
                src="/img/branch_logo_4.svg"
                alt=""
                className="branch_logo"
              />
              <div className="branch_h">{getText("south_korea")}</div>
              <div className="branch_p">{getText("south_korea_descript")}</div>
            </div>
            <img src="/img/branch_8.jpg" alt="" className="branch_img_2" />
          </div>
          <div className="col-lg-7 ">
            <img src="/img/branch_7.jpg" alt="" className="branch_img" />
          </div>
        </div>
      </div>

      <div className="slider ">
        <Swiper
          slidesPerView={1}
          breakpoints={{
            576: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            767: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          loop={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {tour &&
            tour.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="slider_box">
                    <div className="slid_img">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="slide_name">{item.city}</div>
                    {/* <div className="slide_h" dangerouslySetInnerHTML={{ __html: item.text }}/> */}
                    <div className="slide_p">{item.price} {getText("narx")}</div>
                    <div className="slide_btn" onClick={() => navigate(`/slide/${item.id}`)}>{getText("btn1")}</div>
                    {/* <button
                      onClick={() => linkModal(item.id)}
                      className="slide_btn"
                    >
                      Band qilish
                    </button> */}
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>

      {/* {orderModal ? (
        <>
          <form onSubmit={link} action="" className="modalcha">
            <div className="modal_name">
              <input
                className="inp_name"
                placeholder="Test"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                type="text"
              />
            </div>
            <div className="familiya">
              <input
                className="inp_name"
                placeholder="Test"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                required
                type="text"
              />
            </div>
            <div className="phone">
              <input
                className="inp_name"
                placeholder="Test"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                type="text"
              />
            </div>
            <div className="email">
              <input
                className="inp_name"
                placeholder="Test"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                type="text"
              />
            </div>
            <div className="message">
              <input
                className="inp_name"
                placeholder="Test"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                type="text"
              />
            </div>
            <div className="payment">
              <select className="inp_name">
                <option value=""><img src="" alt="" /></option>
                <option value=""><img src="" alt="" /></option>
              </select>
            </div>
            <button className="inp_btn" type="submit">To'lov qilish</button>
          </form>
          <div onClick={setFalse} className="shadow"></div>
        </>
      ) : (
        <></>
      )}
      {payModal ? (
        <>
          <form action="" className="modalcha">
            <div className="header_payment">
              <img src="" alt="" />
              <h1 className="mb-3">Payme to'lov tizimi</h1>
            </div>
            <div className="card_number">
              <h3 className="mb-3">Karta raqamingizni kiriting!</h3>
              <input
                className="inp_name"
                value={card}
                onChange={(e) => setCard(e.target.value)}
                required
                type="number"
                placeholder=""
              />
            </div>
            <div className="amal_qilish">
              <h3 className="mb-3">Amal qilish muddati!</h3>
              <input
                className="inp_name"
                value={num}
                onChange={(e) => setNum(e.target.value)}
                required
                type="text"
              />
            </div>
            <button className="inp_btn" onClick={CreateCard} type="submit">
              Tasdiqlash
            </button>
          </form>
          <div onClick={setFalse} className="shadow"></div>
        </>
      ) : (
        <></>
      )}
      {parolModal ? (
        <>
          <form onSubmit={send_code} action="" className="modalcha">
            <div className="header_payment">
              <img src="" alt="" />
              <h1 className="mb-3">Payme to'lov tizimi</h1>
            </div>
            <div className="password_number">
              <h3 className="mb-3">Tasdiqlash kodini kiriting!</h3>
              <input
                className="inp_name"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
                type="password"
                placeholder="********"
              />
            </div>
            <button className="inp_btn" type="submit">Tasdiqlash</button>
          </form>
          <div onClick={setFalse} className="shadow"></div>
        </>
      ) : (
        <></>
      )} */}

      <div id="4" className="Abroad ">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center">
              <img src="/img/about_uzb.svg" alt="" className="abr_img" />
              <div className="abr_h">{getText("about_uzb_title")}</div>
              <div className="abr_p">{getText("about_uzb_text")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="Abroad_2 ">
        <div className="row">
          <div className="col-lg-7 ">
            <img src="/img/ab_1.jpg" alt="" className="branch_img" />
          </div>
          <div className="col-lg-5 d-flex flex-column h-100">
            <div className="branch_box">
              <img src="/img/ab_logo_1.svg" alt="" className="branch_logo" />
              <div className="branch_h">{getText("tashkent")}</div>
              <div className="branch_p">{getText("tashkent_descript")}</div>
            </div>
            <img src="/img/ab_2.jpg" alt="" className="branch_img_2" />
          </div>
          <div className="col-lg-5 d-flex flex-column h-100">
            <div className="branch_box">
              <img src="/img/ab_logo_2.svg" alt="" className="branch_logo" />
              <div className="branch_h">{getText("samarkand")}</div>
              <div className="branch_p">{getText("samarkand_descript")}</div>
            </div>
            <img src="/img/ab_4.jpg" alt="" className="branch_img_2" />
          </div>
          <div className="col-lg-7 ">
            <img src="/img/ab_3.jpg" alt="" className="branch_img" />
          </div>
          <div className="col-lg-7 ">
            <img src="/img/ab_5.jpg" alt="" className="branch_img" />
          </div>
          <div className="col-lg-5 d-flex flex-column h-100">
            <div className="branch_box">
              <img src="/img/ab_logo_3.svg" alt="" className="branch_logo" />
              <div className="branch_h">{getText("bukhara")}</div>
              <div className="branch_p">{getText("bukhara_descript")}</div>
            </div>
            <img src="/img/ab_6.jpg" alt="" className="branch_img_2" />
          </div>
          <div className="col-lg-5 d-flex flex-column h-100">
            <div className="branch_box">
              <img src="/img/ab_logo_4.svg" alt="" className="branch_logo" />
              <div className="branch_h">{getText("khiva")}</div>
              <div className="branch_p">{getText("khiva_descript")}</div>
            </div>
            <img src="/img/ab_8.jpg" alt="" className="branch_img_2" />
          </div>
          <div className="col-lg-7 ">
            <img src="/img/ab_7.jpg" alt="" className="branch_img" />
          </div>
          <div className="Abroad_3">
            <div className="branch_box">
              <img src="/img/ab_logo_4.svg" alt="" className="branch_logo" />
              <div className="branch_h">{getText("nature")}</div>
              <div className="branch_p">{getText("nature_descript")}</div>
            </div>
            <img src="/img/ab_8.jpg" alt="" className="branch_img_3" />
          </div>
        </div>
      </div>

      <div id="5" className="Gallery ">
        <div className="row">
          <div className="col-12">
            <div className="gal_name">{getText("gallery")}</div>
          </div>
          <div className="col-lg-4 ">
            <img src="/img/gal_1.jpg" alt="" className="gal_img" />
          </div>
          <div className="col-lg-4 ">
            <img src="/img/gal_2.jpg" alt="" className="gal_img" />
          </div>
          <div className="col-lg-4">
            <img src="/img/gal_3.jpg" alt="" className="gal_img" />
          </div>
          <div className="col-12 text-center">
            <a href="" className="gal_btn">
              {getText("show-more")}
            </a>
          </div>
        </div>
      </div>
      <div id="6" className="Partner">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="part_name">{getText("partners")}</div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-3 d-flex align-items-center">
              <img src="/img/part_1.png" alt="" className="part_img" />
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <img src="/img/part_2.png" alt="" className="part_img" />
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <img src="/img/part_3.png" alt="" className="part_img" />
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <img src="/img/part_4.png" alt="" className="part_img" />
            </div>
          </div>
        </div>
      </div>
      <div id="7" className="Contact">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="con_name">{getText("contacts")}</div>
            </div>
          </div>
          <div className="row justify-content-between">
            <form
              action=""
              className="col-lg-6 d-flex flex-column align-items-lg-end align-items-center "
            >
              <div className="con_inp_box">
                <div className="con_inp_main">
                  <div className="con_inp_name">{getText("name")}</div>
                  <input type="text" name="" id="" className="con_inp" />
                </div>
                <div className="con_inp_main">
                  <div className="con_inp_name">{getText("e-mail")}</div>
                  <input type="text" name="" id="" className="con_inp" />
                </div>
              </div>
              <div className="con_inp_box">
                <div className="con_inp_main">
                  <div className="con_inp_name">{getText("message")}</div>
                  <textarea type="text" name="" id="" className="con_inp_2" />
                </div>
              </div>
              <div className="con_check">
                <input type="checkbox" name="" id="" className="inp_ratio" />
                <div className="inp_ratio_name">
                  {getText("checkbox_label")}
                </div>
              </div>
              <button className="con_btn">{getText("send")}</button>
            </form>
            <div className="col-lg-6 d-flex flex-column align-items-lg-start align-items-center">
              <div className="con_h">{getText("contacts_text")}</div>
              <a
                target={"_blank"}
                rel="noreferrer"
                href="tel:+998909988048"
                className="con_tel"
              >
                +998 (90) 998 80 48
              </a>
              <br />
              <a
                target={"_blank"}
                rel="noreferrer"
                href="tel:+998998752127"
                className="con_tel"
              >
                +998 (99) 875 21 27
              </a>

              <div className="con_soc">
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.facebook.com/profile.php?id=100090132759267"
                  className="con_soc_a"
                >
                  <img src="/img/facebook.png" alt="" />
                </a>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.instagram.com/p/CoUXTlxJlf5/?igshid=YmMyMTA2M2Y="
                  className="con_soc_a"
                >
                  <img src="/img/instagram.png" alt="" />
                </a>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://t.me/+RthO4fqRTTSce5a4"
                  className="con_soc_a"
                >
                  <img src="/img/telegram.png" alt="" />
                </a>
              </div>
              <a
                target={"_blank"}
                rel="noreferrer"
                href="https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=cherrytravel.uz@gmail.com"
                className="con_mail"
              >
                e-mail: cherrytravel.uz@gmail.com{" "}
              </a>
              <div className="con_adres">
                Address: Republic of Uzbekistan, Tashkent city, Index: 100000
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <iframe
                className="maps_map"
                title="Location"
                loading="lazy"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3563.455876767291!2d69.2996754605705!3d41.31979897879771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef54e564b1a5d%3A0x3491ed5450454544!2sCHERRY%20TRAVEL!5e0!3m2!1sru!2s!4v1678165293787!5m2!1sru!2s"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <img src="/img/logo_2.svg" alt="" className="foot_logo" />
            </div>
            <div className="col-12 d-flex justify-content-center flex-sm-row flex-column  align-items-center">
              <a href="#1" className="foot_a">
                {getText("home")}
              </a>
              <a href="#2" className="foot_a">
                {getText("about")}
              </a>
              <a href="#4" className="foot_a">
                {getText("about_uzb")}
              </a>
              <a href="#5" className="foot_a">
                {getText("gallery")}
              </a>
            </div>
            <div className="foot_h">Copyright © Tashkent 2023</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { API_PATH, LANGUAGE } from '../tools/constant';
import { getLanguage, getText } from "../locales";
import { redirect, useNavigate, useParams } from "react-router-dom";
import NavbarOther from '../components/NavbarOther';
import './style.css'

const Slide = () => {
    const [card, setCard] = useState("");
    const [num, setNum] = useState("");
    const [tour, setTour] = useState([]);
    const [orderModal, setOrderModal] = useState(false);
    const [payModal, setPayModal] = useState(false);
    const [parolModal, setParolModal] = useState(false);
    const [tour_id, setTour_id] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [message, setMessage] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [burger, setBurger] = useState(false);
    const [orderId, setOrderId] = useState();
    const [paymentId, setPaymentId] = useState();
    const [paymentMethod, setPaymentMethod] = useState(['click', 'payme']);

    const [id, setId] = useState("")
    const [paycha, setPaycha] = useState();
    const { itemId } = useParams();
    const [offer, setOffers] = useState([])
    const [offerid, setOfferid] = useState('')
    const [expended, setExpended] = useState(false)
    const [checked, setChecked] = useState(false)

    const [semessage, setSemessage] = useState('');

    const handleChange = event => {
        const result = event.target.value.replace(/[^a-z]/gi, '');

        setSemessage(result);
    };
    function refreshPage() {
        if (setChecked(!checked)) {
            // alert(getText('tur'))
            alert('Yoq')
        }
        else {
            alert(getText('tur'))
        }

        // localStorage.setItem(NAME)

    }
    // console.log(itemId);

    const formData = new FormData();
    const formData7 = new FormData();

    formData7.append('amount', 1000)

    // const link = () => {
    //     redirect(axios.post('http://olber.kokoagency.uz/en/news/click/process/click/transaction/create/', formData7)
    //         .then((res) => {
    //             console.log(res);
    //         }))
    //     setOrderModal(false);
    //     setPayModal(true);

    //     axios
    //         .post(API_PATH + "news/order-create/", {
    //             phone,
    //             email,
    //             surname,
    //             name,
    //             message,
    //             tour: tour_id,
    //         })
    //         .then((response) => {
    //             setOrder_id(response.data.id);
    //             console.log(response.data);
    //         });

    // };

    const tanla = (id) => {
        setId(id);
        setPaycha(!paycha)
    }

    const CreateCard = (e) => {
        e.preventDefault();


        axios.post(API_PATH + `news/${paymentMethod[paymentId]}/token/`, { card_number: card, expire_date: num })
            .then((res) => {
                console.log(res);
                localStorage.setItem("cardToken", res.data.card_token);
            })
            .then(() => {
                setPayModal(false);
                setParolModal(true);
            })
            .catch((err) => {
                console.log(err.response.status);
            });

    };

    const createOrder = (e) => {
        e.preventDefault();

        axios.post(API_PATH + "news/order-create/", { name, email, phone, message, surname, tour: tour_id })

            .then((res) => {
                console.log(res);
                setOrderId(res.data.id);
            })
            .then(() => {
                setOrderModal(false)
                setPayModal(true);
                console.log(payModal)
            })

    }

    useEffect(() => {
        axios.get(API_PATH + `news/tour/${itemId}`)
            .then((res) => {
                console.log(res.data);
                setTour([res.data]);
            });
    }, []);

    // window.location.replace(redirectLink);
    // const setFalse = () => {
    //     axios.post('http://olber.kokoagency.uz/en/news/click/process/click/transaction/create/', { "amount": 1000 })
    //         .then((res) => {
    //             console.log(res);
    //         })
    //     setOrderModal(false);
    //     setParolModal(false);
    //     setPayModal(false);
    // };

    const setFalse = () => {
        setOrderModal(false);
        setParolModal(false);
        setPayModal(false);
    };

    const linkModal = (id) => {
        window.scrollTo(0, 0);
        setOrderModal(true);
        console.log(id);
        setTour_id(id);
    };

    const [code, setCode] = useState("");
    const [token, setToken] = useState("");
    const formData2 = new FormData();
    const [order_id, setOrder_id] = useState("");

    // formData2.append("verify_code", code);
    // formData2.append("token", token); 

    const notify = () => toast("Wow so easy!");
    const notify2 = () => toast("Xatolik mavjud");
    const nav = useNavigate()

    // console.log(tour[0].id);

    const send_code = (e) => {
        e.preventDefault();

        const card_token = localStorage.getItem("cardToken");

        axios.post(API_PATH + `news/${paymentMethod[paymentId]}/verify/`, { card_token: card_token, sms_code: code })
            .then((res) => {
                // 9860600435405540 
                // 0527 

                const errCode = res.data.error_code;
                console.log('sms verification', res);

                if (!isNaN(errCode == 0)) {
                    axios.post(API_PATH + `news/${paymentMethod[paymentId]}/pay/`, { card_token: card_token, amount: 1000, id: orderId })
                        .then((res) => {
                            console.log('payment status', res);
                            if (res.status === 200) {
                                notify()
                            }
                        })
                        .then(() => {
                            localStorage.removeItem('cardToken');
                        })
                        .catch((err) => {
                            notify()
                        })
                } else {
                    alert("invalid data")
                }

                return res.data.error_code;
            })
            .catch((err) => {
                console.log(err)
                notify2()
            })
        // nav('/')

    };


    return (
        <>
            <NavbarOther />
            {tour && tour.map((item, index) => {
                return (
                    <>
                        <div className="ss">
                            <div className="container">
                                <div className="row">


                                    <div className="col-lg-12">
                                        <div className="for_image">
                                            <img src={item.image} alt="" />
                                        </div>
                                        <div className="col-lg-12 mx-auto">
                                            <h1 className='mt-5 text_city'>{item.city}</h1>
                                            <p className='mt-5 city_p'>{item.text}</p>
                                            <p className='span_text'> {getText("narx1")} {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {getText("narx")} {getText("narx2")}  </p>
                                            <ul>
                                                <li>{item.price_text}</li>

                                            </ul>
                                            <div className="mt-2 for_price_text">
                                                <h2>{item.price_header}</h2>
                                                <ul>
                                                    {/* <li>{item.price_text}</li> */}
                                                    <li>{item.sub_price}</li>
                                                </ul>
                                            </div>
                                            <div className="for_price_text">
                                                <h2>{item.excursion}</h2>
                                                <ul>
                                                    <li>
                                                        {item.excursion_text}
                                                    </li>

                                                </ul>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-12 mx-auto">
                                        <button
                                            onClick={() => linkModal(item.id)}
                                            className="btn_sliderrr"
                                        >
                                            {getText("btn1")}
                                        </button>
                                    </div>
                                    <div className="col-12 mx-auto mt-4">
                                        <a href="tel:+998909988048">
                                            <button
                                                className="btn_sliderrr"
                                            >
                                                {getText("bizblan")}
                                            </button>
                                        </a>
                                    </div>
                                    <div className="col-12 for_zabron">
                                        <div className="off_btn for_Abdulaziz" onClick={() => setExpended(!expended)}>{expended ? <span>{getText("tur_2")}</span> : <span>{getText("tur_2")}</span>}</div>
                                        {expended &&
                                            <div className=''>
                                                <form onSubmit={refreshPage} required action="">
                                                    <input required id='inp_id semessage'
                                                        name='semessage'
                                                        onChange={handleChange}
                                                        className='input_Ab' type="text" placeholder={getText("feed_p_1")} onKeyPress={(event) => {
                                                            if (/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} />
                                                    <input required maxLength={9} minLength={9} className='input_Ab' type="text" onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                        placeholder={getText("feed_p_2")} />
                                                    <button id='btn_sub' className='off_btn for_Abdulaziz2' type='submit'>{getText("off_btn")}</button>
                                                </form>

                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>

                        </div>

                    </>
                )
            })}
            {payModal ? (
                <>
                    <form action="" className="modalcha">
                        <div className="header_payment">
                            <img src="" alt="" />
                            <h1 className="mb-3">{tanla ? getText("btn2") : getText("btn2")}</h1>
                        </div>
                        <div className="card_number">
                            <h3 className="mb-3">{getText("btn3")}</h3>
                            <input
                                className="inp_name"
                                value={card}
                                onChange={(e) => setCard(e.target.value)}
                                required
                                type="number"
                                placeholder=""
                            />
                        </div>
                        <div className="amal_qilish">
                            <h3 className="mb-3">{getText("btn4")}</h3>
                            <input
                                className="inp_name"
                                value={num}
                                onChange={(e) => setNum(e.target.value)}
                                required
                                type="text"
                            />
                        </div>
                        <button className="inp_btn" onClick={(e) => CreateCard(e)} type="submit">
                            {getText("btn5")}
                        </button>
                        <div onClick={setFalse} className="x">
                            <div className="x_1"></div>
                            <div className="x_2"></div>
                        </div>
                    </form>
                    <div onClick={setFalse} className="shadow"></div>
                </>
            ) : (
                <></>
            )}
            {parolModal ? (
                <>
                    <form action="" className="modalcha">
                        <div className="header_payment">
                            <img src="" alt="" />
                            <h1 className="mb-3">{id == 1 ? getText("btn2") : getText("btn2_click")}</h1>
                        </div>
                        <div className="password_number">
                            <h3 className="mb-3">
                                {getText("btn6")}
                            </h3>
                            <input
                                className="inp_name"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                required
                                type="password"
                                placeholder="********"
                            />
                        </div>
                        <button className="inp_btn" type="submit" onClick={(e) => send_code(e)}>{getText("btn13")}</button>
                        <div onClick={setFalse} className="x">
                            <div className="x_1"></div>
                            <div className="x_2"></div>
                        </div>
                    </form>
                    <div onClick={setFalse} className="shadow"></div>
                </>
            ) : (
                <></>
            )}

            {orderModal ? (
                <>
                    <form action="" className="modalcha">
                        <div className="modal_name">
                            <input
                                className="inp_name"
                                placeholder={getText("btn12")}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                type="text"
                            />
                        </div>
                        <div className="familiya">
                            <input
                                className="inp_name"
                                placeholder={getText("btn11")}
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                required
                                type="text"
                            />
                        </div>
                        <div className="phone">
                            <input
                                className="inp_name"
                                placeholder={getText("btn10")}
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                                type="text"
                            />
                        </div>
                        <div className="email">
                            <input
                                className="inp_name"
                                placeholder={getText("btn9")}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                type="text"
                            />
                        </div>
                        <div className="message">
                            <input
                                className="inp_name"
                                placeholder={getText("btn8")}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                                type="text"
                            />
                        </div>
                        <div className="payment">
                            <div className="pay_box ">
                                <div id='click' className="pay_main d-flex flex-column-reverse">
                                    <input required type="radio" name="1" id="1" onClick={() => setPaymentId(0)} />
                                    <div onClick={() => setPaycha(!paycha)} className={`pay_img_box ${paycha ? '' : 'active'}`}>
                                        <img onClick={() => tanla(1)} src="/img/click.png" alt="" />
                                    </div>
                                </div>
                                <div id='payme' className=" pay_main d-flex flex-column-reverse">
                                    <input required type="radio" name="1" id="1" onClick={() => setPaymentId(1)} />
                                    <div className={`pay_img_box ${paycha ? '' : ''}`}>
                                        <img onClick={() => tanla(2)} src="/img/payme.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button className="inp_btn" type="submit" onClick={(e) => createOrder(e)}>
                            {getText("btn7")}
                        </button>
                        <div onClick={setFalse} className="x">
                            <div className="x_1"></div>
                            <div className="x_2"></div>
                        </div>

                    </form>

                    <div onClick={setFalse} className="shadow"></div>
                </>
            ) : (
                <></>
            )
            }

            <div className="Footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <img src="/img/logo_2.svg" alt="" className="foot_logo" />
                        </div>
                        <div className="col-12 d-flex justify-content-center flex-sm-row flex-column  align-items-center">
                            <a href="#1" className="foot_a">
                                {getText("home")}
                            </a>
                            <a href="#2" className="foot_a">
                                {getText("about")}
                            </a>
                            <a href="#4" className="foot_a">
                                {getText("about_uzb")}
                            </a>
                            <a href="#5" className="foot_a">
                                {getText("gallery")}
                            </a>
                        </div>
                        <div className="foot_h">Copyright © Tashkent 2023</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Slide
import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";

function Main() {
    return (
        <>
            <Home />
        </>
    );
}

export default Main;

import React, { useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { getLanguage, getText } from "../locales";
import { LANGUAGE } from "../tools/constant";

const Navbar = () => {

  const changeLanguage = (e) => {
    if (getLanguage() !== e) {
      localStorage.setItem(LANGUAGE, e);
      console.log(e);
      document.location.reload(true);
    }
  };
  const [burger, setBurger] = useState(false);

  return (
    <div className="Navbar">
      <div className="container">
        <div className="row">
          <div className="col-3 d-flex align-items-center">
            <div className="nav_lang_box">
              <div
                onClick={() => changeLanguage("uz")}
                value="uz"
                className="nav_lang"
              >
                <img src="/img/lang_2.png" alt="" />
              </div>
              <div
                onClick={() => changeLanguage("ru")}
                value="ru"
                className="nav_lang"
              >
                <img src="/img/lang_1.png" alt="" />
              </div>
              <div
                onClick={() => changeLanguage("en")}
                value="en"
                className="nav_lang"
              >
                <img src="/img/lang_3.png" alt="" />
              </div>
            </div>
          </div>
          <div
            className={`col-9 d-flex align-items-center justify-content-end nav_a_box ${burger ? "active" : ""
              }`}
          >
            <AnchorLink onClick={() => setBurger(!burger)} href="#1" className="nav_a">
              {getText("home")}
            </AnchorLink>
            <AnchorLink onClick={() => setBurger(!burger)} href="#2" className="nav_a">
              {getText("about")}
            </AnchorLink>
            <AnchorLink onClick={() => setBurger(!burger)} href="#3" className="nav_a">
              {getText("abroad")}
            </AnchorLink>
            <AnchorLink onClick={() => setBurger(!burger)} href="#4" className="nav_a">
              {getText("about_uzb")}
            </AnchorLink>
            <AnchorLink onClick={() => setBurger(!burger)} href="#5" className="nav_a">
              {getText("gallery")}
            </AnchorLink>
            <AnchorLink onClick={() => setBurger(!burger)} href="#6" className="nav_a">
              {getText("partners")}
            </AnchorLink>
            <AnchorLink onClick={() => setBurger(!burger)} href="#7" className="nav_a">
              {getText("contacts")}
            </AnchorLink>
          </div>

          <div
            onClick={() => setBurger(!burger)}
            className={`burger pr-4 ml-auto d-flex d-lg-none ${burger ? "burgered" : ""
              }`}
          >
            <div className="burger_up "></div>
            <div className="burger_down"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
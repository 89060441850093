export const uz = {
  "header-title": "Biz bilan dunyo bo'ylab sayohat qiling",
  home: "Asosiy",
  "show-more": "Ko'proq korsatish",
  about: "Biz haqimizda",
  about_uzb: "O'zbekiston",
  tashkent: "Toshkent",
  tashkent_descript:
    "Oʻzbekistonning poytaxti va eng yirik shahri. Bu Markaziy Osiyodagi eng zich joylashgan shahar. Oʻzbekistonning shimoli-sharqida, Qozogʻiston bilan chegara yaqinida joylashgan. Toshkent turkiy tosh va kent soʻzidan kelib chiqqan boʻlib, soʻzma-soʻz tarjimada “Tosh shahar” yoki “Toshlar shahri” deb tarjima qilinadi.",
  samarkand: "Samarqand",
  samarkand_descript:
    "Dunyoning eng buyuk shoir va faylasuflari shaharga ko‘p nomlar berganlar – qalb bog‘i, sharq gavhari, dunyo ko‘zgusi va hatto yer yuzi. Biroq ular bu go‘zal shaharning go‘zalligi va boyligini tasvirlay olmadilar.",
  bukhara: "Buxoro",
  bukhara_descript:
    "U 2500 yoshdan oshgan va tarix nafasini o'z ichiga olganga o'xshaydi. Eron entsiklopediyasiga koʻra, shahar nomi soʻgʻdcha “baxtli joy” soʻzidan kelib chiqqan; u sanskrit tilidagi \"ma'bad\" so'zidan olinganligi ham aytiladi. Buxoro islomning dunyodagi yetti muqaddas shaharlaridan biridir.",
  khiva: "Xiva",
  khiva_descript:
    "O'zining sokinligi bilan hayratga soladigan shahar-muzey. Bu cho'lda joylashgan O'zbekiston durdonasi hisoblanishi ajablanarli emas. Bu yerdan bir vaqtlar Buyuk ipak yo‘li karvonlari o‘tgan. Xiva xonligining poytaxti ham bo'lgan. Xivaga kelganingizda, o'zingizni faqat elektr simlari zamonaviylik ruhini baxsh etadigan haqiqiy o'rta asr shahriga duch kelasiz. Asfaltlangan ko'chalar, loydan qurilgan masjidlar, mohir naqshlar va mahobatli qal'a devorlari hatto eng ilg'or sayyohni ham befarq qoldirmaydi. Butun eski shahar UNESCOning Jahon merosi ro'yxatiga kiritilgan.",
  nature: "Tabiat",
  nature_descript:
    "Iqlimni o'zgartirishni, go'zal tabiatdan, noyob flora va faunadan bahramand bo'lishni, billur ko'llar va shovqinli daryolar qirg'og'ida quyoshga botishni va, ehtimol, sayyoramizning eng baland nuqtasiga chiqishni xohlaysizmi? Endi bu mumkin bo'ldi. O'zbekistonga keling! Ushbu qadimiy mamlakatning betakror tabiati siz uchun uning tabiiy va go'zallik ranglarining butun palitrasini ochadi. O‘zbekistonning dengizga chiqish imkoni yo‘q va buning o‘rnini butun Markaziy Osiyoni qamrab olgan dunyodagi ko‘p sonli eng go‘zal ko‘llar, tabiiy va sun’iy suv havzalari va yirik daryolari bilan qoplaydi. O'zbekistonning ulug'vor tog'lari ko'plab boyliklarni saqlaydi, har yili baland tog' cho'qqilarini bosib o'tadigan yuzlab sayyohlar va ekstremal sportchilarni o'ziga jalb qiladi. Eng go'zal sharsharalar, minglab odamlar, chang'i yonbag'irlari, alp o'tloqlari, qo'riqxonalar va o'rmonlar, kanyonlar va tog' ko'llari, g'orlar va qadimiy tsivilizatsiya joylari - bu mamlakatimizning go'zal tog'li hududlari nimani taklif qilishi mumkinligining to'liq ro'yxati emas. maqtanish.",
  abroad: "Chet elda",
  world_tour_title: "Chet elga sayohatlar",
  abroad_tours_text:
    "Sayohat - bu inson qila oladigan eng yaxshi mashg'ulotlardan biridir. Sayohat qilganingizda siz yangi tajribaga ega bo'lasiz, boshqa mamlakatlar madaniyati bilan tanishasiz va butun dunyo bo'ylab noyob joylarga tashrif buyurasiz. Sayohat - bu chet tillarini o'rganish, mahalliy taomlarni tatib ko'rish va yangi do'stlar bilan tanishish. Dunyo bo'ylab millionlab odamlar ta'tillarini sayohat qilishda o'tkazishadi va men bundan mustasno emasman. Sayohat qilish bizning ishtiyoqimizdir. Yaxshiyamki, ota-onam ham xuddi shunday fikrda. Shunday qilib, har safar bayramimiz bo'lsa, biz yangi joyga borishga harakat qilamiz. Bu yangi shahar yoki yangi mamlakat bo'lishi mumkin.",
  france: "Frantsiya",
  france_descript:
    "Frantsiyaga sayohat haqida o'ylashni boshlaganimizda, birinchi navbatda Parij esga tushadi. Uning afsonaviy ko'chalari romantizmning ta'rifidir. Ammo Notr-Damdan tashqarida, Eyfel minorasi va Yelisey Champslari bir tomonda Normandiya qirg'oqlari va boshqa tomondan Frantsiya Rivierasi bo'lgan ulkan mamlakatdir. Parijdan Shampan va Versalga bir kunlik sayohatlarni Frantsiyada qilinadigan narsalar ro'yxatiga qo'shish oson. Ammo Strasburg, Lion, Kann va Sent-Tropez kabi unutilmas shaharlardan tashqariga sayohat qilish uchun mamlakatning samarali (va qiziqarli) poezd tarmog'idan foydalanishingiz kerak. Glamur, firuza rangli O'rta er dengizi va qadimiy eski shaharlardan tashqari, Frantsiya Alp tog'lari chang'i, piyoda yurish va qoyalarga ko'tarilish birinchi o'ringa chiqadigan manzarali landshaftlarga ega. Ammo, ehtimol, Frantsiyadagi eng muhim sayyohlik joylari uning boy madaniyati, dunyodagi eng mashhur taomlari va sharoblari bilan.",
  greece: "Gretsiya",
  greece_descript:
    "Gretsiya o'zining toza plyajlari va uzoq tarixi bilan mashhur bo'lgan mashhur mamlakatdir. Gretsiyada mamlakat o'tmishini mukammal aks ettiruvchi yuzlab arxeologik va tarixiy yodgorliklar mavjud. Uning landshafti asosan tog'li va erlari juda unumdor emas, bundan tashqari Gretsiya materikida tarqalgan ba'zi vodiylar bundan mustasno. Biroq, Gretsiya suv bilan o'ralgan, xususan, Egey va Ion dengizlari. Mamlakat 1400 dan ortiq orol va orollardan iborat, ammo ulardan faqat 169 tasida istiqomat qiladi. Bu eng mashhur yunon yo'nalishlari, ayniqsa yozda. Katta yoki kichik, yashil yoki qurg'oqchil, kosmopolit yoki tasalli beruvchi dam olish uchun juda mos keladi, bir narsa aniq: bu orollarning barchasi sizga unutilmas va xavfsiz dam olishni taklif qilish uchun barcha zarur va qulayliklarga ega!",
  uae: "Birlashgan Arab Amirliklari",
  uae_descript:
    "Yetti amirlik, bir yo'nalish. Birlashgan Arab Amirliklariga tashrif buyuring va tarix va an'analarga boy mamlakatni kashf eting. Cho'lda sayohat qiling va Arabistonning yovvoyi tomonini o'rganing. Keyin ongingizni, tanangizni va ruhingizni eksklyuziv dam olish maskanlarida dam oling. BAAda jannatingizni toping.",
  south_korea: "Janubiy Koreya",
  south_korea_descript:
    "Osiyoning uchinchi yirik iqtisodiyoti gilos daraxtlari bilan qoplangan tepalikli qishloqlar, neon yoritilgan shaharlar, qadimiy buddist ibodatxonalari va saroylari, koreys xanoklari (an'anaviy uylar), yashirin subtropik orollar va 5000 yillik madaniyat va tarixga ega go'zal baliqchilar qishloqlari aralashmasidir. Janubiy Koreya tashrif buyuradigan Osiyo davlatlari ro‘yxatida bo‘lmasa-da, Janubiy Koreyada barcha fasllar uchun manzillar, qishda chang‘i yonbag‘irlariga aylanadigan qoyali cho‘qqilar, yoz quyoshi ostida yaltirab turgan plyajlar va dengizlar borligini tushunasiz. va kuzda yashil rangdan oltin rangga aylangan milliy bog‘lar, sizga yashirincha ko‘z qisib, imo qiladigan olis orollar.",
  partners: "Hamkorlar",
  contacts: "Shartnomalar",
  who: "Biz kimmiz?",
  operator:
    "Sizni \"Cherry Travel\" kompaniyasi bilan tanishtirishdan mamnunmiz, sizning eng yaxshi dostingiz va butun dunyo bo'ylab ajoyib sayohatingiz bo'yicha ishonchli yo'ldoshingizmiz. Biz bilan birga siz O'zbekistonning go'zal shahar va toglarini, Gretsiyaning muhtasham Egey dengizini va  ajoyib qadimiy memorchiligini ko'rishingiz, Frantsiyadagi Eyfel minorasi tepasiga tashrif buyurishingiz va yana dunyoning boshqa ko'plab obidalarni ko'rish imkoniyatiga ega bo'lasiz.",
  service: "Xizmatlar",
  "service-text":
    "Bizning turoperatorlarimiz, mijozning sayohatga bo'lgan arizasiga ko'ra turli sayyohlik yo'nalishlar bo'yicha turlarni tuzishi va tashkil etishligi mumkin. Masalan: Evropa, Amerika va Osiyoning deyarli barcha davlatlariga. Bizning xizmatlar toplami: Sayyohlik Vizasini olish jarayonida qollab-quvvatlash; Sayyohlarni taklif qilishda va jonashda o'tkazmalarni amalga oshirish; har-xil turdagi mehmonxonalar va \"B&B\"larda xonani sayyohatchlar uchun bron qilish va sayyohatchlarni joylashtirish; mahalliy va xalqaro tarjimonlar va gidlarni yonlash; ish uchrashuvlarini o'tkazish uchun konferentsiya zallarini ijaraga olish va bron qilish; samalyot va poezd chiptalarini bron qilish; avtomobillar, mikroavtobuslar va avtobuslarda sayyohatchlarni tashishni tashkil etish; diqqatga sazovor joylarni tomosha qilishni tashkil etish; ovqatlanish  (Yevropa va o'zbek oshxonalar) - yarim yoki toliq pansion hizmatiga ega qahvahona yoki restoranlarda joylarni bron qilishi va ovqatlanishni tashkil qilish.)",
  about_uzb_title: "O‘zbekiston",
  about_uzb_text:
    "O‘zbekiston Markaziy Osiyoning eng qadimiy mintaqalaridan biri bo‘lib, uning asosiy boyligi boy tarixiy o‘tmishdir. Markaziy Osiyoning markazida joylashgan Oʻzbekiston Respublikasi oʻzining koʻplab meʼmoriy yodgorliklari, ajoyib tabiat manzaralari, muhtasham saroylari va oʻtmish tsivilizatsiyalari qalʼalari xarobalari, jonli madaniy tadbirlari, gastronomik lazzatlari va mashhur hunarmandchilik ustaxonalari bilan mashhur. Birinchi tashrifdanoq yurtimiz odamlarni bu erga qayta-qayta kelishga undaydigan g'ayrioddiy tuyg'ularni uyg'otadi. Oʻzbekiston Respublikasi tarkibiga 12 viloyat va Qoraqalpogʻiston Muxtor Respublikasi kiradi. O‘zbekistonning har bir go‘shasi o‘ziga xos jihatlari bilan o‘ziga jalb etadi. Toshkentda siz dunyodagi eng go‘zal metro bo‘ylab sayr qilishingiz yoki eng qadimgi qo‘lyozma – Usmon Qur’onini ko‘rishingiz mumkin. UNESCO madaniy merosi roʻyxatiga kiritilgan tarixiy shaharlar – Samarqand, Buxoro, Shahrisabz va Xivada oʻtgan davrlar aks-sadosini saqlab qolgan eng qadimiy meʼmoriy yodgorliklarni ziyorat qilish mumkin. Mamlakatimizning eng janubiy qismi – Surxondaryoda tog‘ daralari bo‘ylab sayohat qilish yoki Termiz yaqinidagi qadimiy shaharlar xarobalarini ko‘rish mumkin. Yil fasliga qarab Tyan-Shan tog‘ etaklarida chang‘i uchishingiz yoki Katta Chimyonning eng baland nuqtasiga chiqishingiz mumkin.",
  grow: "Biz o'sib bormoqdamiz",
  grow_text:
    "Biz o'sib bormoqdamiz va yaxshilanmoqdamiz, demak, biz sizning har qanday istaklaringizdan xursandmiz, sizning yaxshi kayfiyatingiz uchun ishlashga tayyormiz.",
  gallery: "Galereya",
  name: "sizning ismi",
  "e-mail": "sizning e-mail",
  message: "sizning savol",
  checkbox_label: "Men shaxsiy ma'lumotlarimni qayta ishlashga roziman",
  contacts_text:
    "Bizga aloqa ma'lumotlaringizni yuboring va menejerlarimiz qisqa vaqt ichida siz bilan bog'lanib, savollaringizga javob berishadi",
  send: "yuborish",

  btn1: "To'lovni amalga oshirish",
  btn2: "To'lov tizimi",
  btn2_click: "Click to'lov tizimi",
  btn3: "Karta raqamingizni kiriting!",
  btn4: "Amal qilish muddati!",
  btn5: "Tasdiqlash",
  btn6: "Tasdiqlash kodini kiriting!",
  btn7: "To'lov qilish",
  btn8: "Xabar",
  btn9: "Email manzilingiz",
  btn10: "Telefon raqamingiz",
  btn11: "Familiya",
  btn12: "Ism",
  btn13: "Tasdiqlash",
  narx: "so'm",
  narx1: "Ekskursiya narxi: ",
  narx2: "dan 1 yoshi kottalar uchun",
  bizblan: "Biz bilan bog'laning",
  tur_2: "Band qilish",
  feed_p_1: "Ism Sharifingiz:",
  feed_p_2: "Telefon raqamingiz:",
  off_btn: "Hoziroq band qiling",
  tur: "Tur bron qilindi",
};

export const LANGUAGE = "app-cherry-language";

// export const API_PATH = `https://olber.kokoagency.uz/${
//     localStorage.getItem(LANGUAGE) ? localStorage.getItem(LANGUAGE) : "uz"
//   }/`;  


export const API_PATH = `https://test.goldblock.uz/${
    localStorage.getItem(LANGUAGE) ? localStorage.getItem(LANGUAGE) : "ru"
}/`; 
  
import React from "react";
import { HashRouter, Route, Routes, BrowserRouter} from "react-router-dom";
import Home from "./page/Home";
import Main from "./page/Main";
import Order from "./page/Order";
import Slide from "./page/Slide";

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route element={<Slide />} path="/slide/:itemId" />
          <Route element={<Home />} path="/" />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;

export const en = {
  "header-title": "TRAVEL AROUND THE WORLD WITH US",
  home: "home",
  "show-more": "show more",
  about: "About us",
  about_uzb: "Uzbekistan",
  tashkent: "Tashkent city",
  tashkent_descript:
    'The capital and largest city of Uzbekistan. It is the most populous city in Central Asia. It is located in northeastern Uzbekistan, near the border with Kazakhstan. Tashkent comes from the Turkic tash and kent, literally translated as "Stone City" or "City of Stones".',
  samarkand: "Samarkand city",
  samarkand_descript:
    "The greatest poets and philosophers of the world have given the city many names – the garden of the soul, the pearl of the east, the mirror of the world and even the face of the earth. However, they was not be able to describe the beauty and richness of this beautiful city.",
  bukhara: "Bukhora city",
  bukhara_descript:
    "Over 2,500 years old and seems to be emanating the breath of history. According to the Iranian Encyclopedia, the name of the city came from the Sogdian for ‘lucky place’; it is also said to derive from the Sanskrit for ‘temple’. Bukhara is one of the world’s seven holy cities of Islam.",
  khiva: "Khiva city",
  khiva_descript:
    "Is a museum city that impresses with its tranquility. No wonder it is considered the pearl of Uzbekistan, located in the desert. Once the caravans of the Great Silk Road passed here. Khiva was also the capital of the Khanate of Khiva. Once in Khiva, you will find yourself in a real medieval city, where the spirit of modernity is given out only by electric wires. Cobbled streets, clay mosques, elaborate patterns and majestic fortress walls will impress even the most advanced tourist. The entire old town is included in the UNESCO World Heritage List.",
  nature: "Nature",
  nature_descript:
    "Do you want to change the climate, enjoy the picturesque nature, unique flora and fauna, sunbathe on the coast of crystal lakes and noisy rivers, and possibly ascend to the highest point on the planet? Now it has become possible. Come to Uzbekistan! The unique nature of this ancient country will open for you the whole palette of its colors of natural and pristine beauty. Uzbekistan has no access to the sea and compensates for this with a huge number of the most beautiful lakes in the world, natural and artificial reservoirs and large rivers covering the whole of Central Asia. The majestic mountains of Uzbekistan store many treasures, attracting hundreds of tourists and extreme sportsmen who annually storm the high mountain peaks. The most beautiful waterfalls, peaks of many thousands, skiing tracks, alpine meadows, nature reserves and forest areas, canyons and mountain lakes, caves and sites of ancient civilizations - this is not a complete list of what the picturesque mountainous regions of our country can boast of.",
  abroad: "Abroad",
  world_tour_title: "Tours to Abroad",
  abroad_tours_text:
    "Travelling is one of the best activities a person can do. When you travel you get new experience, learn about the cultures of other countries and visit unique places around the whole world. Travelling is learning foreign languages, tasting local dishes and meeting new friends.Millions of people throughout the world spend their holidays travelling and I’m no exception. Travelling is our passion. Luckily, my parents are of the same opinion. So, each time we have a holiday, we try to go somewhere new. It can be either a new city, or a new country.",
  france: "France",
  france_descript:
    "When the mind ponders a trip to France, Paris quickly makes an appearance. Its storied streets are the very definition of romanticism. But beyond the Notre Dame, the Eiffel Tower and the Champs-Élysées is a sprawling country with the rugged Normandy coast on one side and the French Riviera on the other. Day trips from Paris to Champagne and Versailles are easy to add to the list of things to do in France. But one needs to make use of the country’s efficient (and fun) train network to venture beyond to such memorable towns as Strasbourg, Lyon, Cannes, and St. Tropez. Beyond glamour, the turquoise Mediterranean and ancient old towns, the French Alps harbor spectacular scenery where skiing, hiking, and climbing come to the fore. But perhaps the biggest tourist attractions in France are found among its rich culture with food and wines that are among the most celebrated in the world.",
  greece: "Greece",
  greece_descript:
    "Greece is a famous country, popular for its clean beaches and its long history. There are hundreds of archaeological and historical sites to visit in Greece that gloriously depict the country's past. Its landscape is mainly mountainous and the terrain is not very fertile, except for some valleys scattered along the Greek mainland. However, Greece is surrounded by water, in particular, the Aegean and the Ionian Sea. The country consists of more than 1,400 islands and islets, but only 169 of them are inhabited. They are the most popular Greek destinations, especially in summer. Big or tiny, green or arid, ideal for cosmopolitan or relaxing vacations, one thing is for sure: all these islands have the requirements and facilities to offer you memorable and safe vacations!",
  uae: "United Arab Emirates",
  uae_descript:
    "Seven emirates, one destination - Visit the United Arab Emirates and discover a land rich in history and tradition. Take a tour to the desert and explore the wild side of Arabia. Then, relax your mind, body and soul at the exclusive resorts. Find your paradise in the UAE.",
  south_korea: "South Korea",
  south_korea_descript:
    "Asia’s third-largest economy is a melange of hilly countrysides dotted with cherry trees, neon-lit urban sprawls, ancient Buddhist temples and palaces, quaint fishing villages with Korean hanok (traditional houses), hidden sub-tropical islands and 5000 years of culture and history. While South Korea may not feature on your list of Asian countries to visit, it will once you realise that South Korea tourism has destinations for all seasons, craggy peaks that transform into skiing slopes in winter, beaches and the sea that gleam under the summer sun and national parks that turn from green to gold in the fall and remote islands that wink secretively at you and beckon.",
  partners: "Partners",
  contacts: "Contacts",
  who: "WHO WE ARE?",
  operator:
    "We are pleased to introduce you - Cherry Travel, your best friend and guide on our wonderful trip around the world. Together with us you will see the beautiful steppes and mountains of Uzbekistan, the magnificent Aegean Sea and the wonderful ancient architecture of Greece, visit the top of the Eiffel Tower in France and much, much more",
  service: "our service",
  "service-text":
    'Our tour operators can compose and organize different types of tours according to customers application for the trip, such as the following: The set of our services: visa support; transfers on arrival and departure; room-reservation and accommodation in various types of hotels and "B&B"; local interpreters and guides; conference halls rental for holding business meetings; air, train tickets reservation; transportation by cars, mini-buses and coaches; sightseeing; meals - half board or full board (European and Uzbek cuisines)',
  about_uzb_title: "About Uzbekistan",
  about_uzb_text:
    "Uzbekistan is one of the oldest regions of Central Asia, the main asset of which is a rich historical past. Located in the heart of the Central Asia, the Republic of Uzbekistan is famous for its numerous architectural monuments, incredible natural landscapes, magnificent palaces, and ruins of fortresses of past civilizations, outstanding cultural events, gastronomic delights and famous craft workshops. From the first visit, our country evokes extraordinary feelings that prompt us to come here repeatedly.The Republic of Uzbekistan includes 12 regions and the autonomous Republic of Karakalpakstan.Each corner of Uzbekistan attracts with its features. In Tashkent, you can take a walk in the most beautiful metro in the world or see the most ancient manuscript – the Koran of Usman. In the historical cities included in the UNESCO Cultural Heritage List – Samarkand, Bukhara, Shakhrisabz and Khiva, you can visit the most ancient architectural monuments that have preserved echoes of past eras. In the southernmost part of the country – Surkhandarya, you can travel through mountain gorges or see the ruins of the ancient cities near Termez.Depending on the time of year, you can go skiing in the foothills of the Tien Shan or climb the highest point of Big Chimgan.",
  grow: "We are growing",
  grow_text:
    "We are growing and improving, which means we are glad for any of your wishes, ready to work for your good mood",
  gallery: "gallery",
  name: "Your name",
  "e-mail": "Your e-mail",
  message: "Your message",
  checkbox_label: "I agree to the processing of my personal data",
  contacts_text:
    "Send us your contact details and our managers will contact you shortly and answer your questions",
  send: "send",

  btn1: "To book",
  btn2: " Payment system",
  btn2_click: "Click payment system",
  btn3: "Enter the card number!",
  btn4: "Expiration date!",
  btn5: "Confirm",
  btn6: "Enter the verification code!",
  btn7: "To pay",
  btn8: "Message",
  btn9: "Email",
  btn10: "Phone number",
  btn11: "Surname",
  btn12: "Name",
  btn13: "Confirm",
  narx: "so'm",
  narx1: "Tour price: from",
  narx2: "for 1 adult",
  bizblan: "Contact us",
  tur_2: "Order Tour Package",
  feed_p_1: "Your Name:",
  feed_p_2: "Your phone number:",
  off_btn: "Book now",
  tur: "The tour is booked",
};
